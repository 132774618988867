.App {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}

h3{
  padding-top: 1em;
}